import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ThankYouPage = ({ data }, location) => {

  const siteTitle = data.site.siteMetadata.title

  return (

    <Layout title={siteTitle}>

      <Seo title="Obrigado!" keywords={[`contato`, `design`, `ui`, `ux`, `front-end`]} />

        <header className="page-head post-content-body">

            <h1 className="page-head-title">
                <span role="img" aria-label="Thumbs Up">👍</span><br />
                Obrigdo pelo contato!
            </h1>

        </header>

    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ThankYouPage location={props.location} data={data} {...props} />
    )}
  />
)
